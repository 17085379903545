.observatie_item_date {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  padding: 5px;
}
.observatie_item_text {
  text-align: left;
  width: 90%;
  height: 50px;
  overflow: hidden;
}

.noObservatii {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
}

@media (prefers-color-scheme: dark) {
  .noObservatii {
    color: white;
  }
}

.deleteObservatieButton {
  width: 40px;
  height: 40px;
  background: #c60800;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteObservatieButton:hover {
  cursor: pointer;
  background-color: #c6080d;
}

.modificare_stergere_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}