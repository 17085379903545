body {
  height: 100%;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: "100%";
  border-bottom: 0px;
  height: 50px;
}

.closeModalButton:hover {
  cursor: pointer;
}

.modal-body {
  padding-top: 0px;
}

.dropdown_with_space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_default {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 36px;
}

.dropdown_istoric {
  width: auto;
}
.dropdown_istoric_menu {
  height: 600px;
  overflow: scroll;
}

.dropdown_dispecer_options {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 371px;
  background-color: #ddd;
}

.dropdown_dispecer_options_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown_ore_lucrate {
  height: 600px;
  overflow: scroll;
}

/* .btn {
  color: black;
} */
.btn-secondary {
  background-color: hsl(0, 0%, 100%);
  border-color: #ddd;
  color: black;
}

.btn-secondary:focus {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: #ddd;
  color: black;
  box-shadow: none;
  outline: none !important;
}
.show > .btn-secondary.dropdown-toggle {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: #ddd;
  color: black;
  box-shadow: none;
  outline: none !important;
}
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: #ddd;
  color: black;
  box-shadow: none;
  outline: none !important;
}
.dropdown-toggle:focus {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: #ddd;
  color: black;
  box-shadow: none;
}

.btn-secondary:hover {
  background-color: hsl(0, 0%, 95%);
  border-color: #ddd;
  color: black;
}

.dropdown-toggle:hover {
  background-color: hsl(0, 0%, 95%);
  border-color: #ddd;
  color: black;
  box-shadow: none;
}
