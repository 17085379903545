.locations_add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 350px;
}

.location_already_exists {
  text-align: center;
}

.location_already_exists h3 {
  color: #c60800;
  animation-name: locationAlreadyExistsError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes locationAlreadyExistsError {
  0% {
    color: black;
  }
  50% {
    color: #c60800;
  }
  100% {
    color: black;
  }
}

.locatieInputScadentaAscensoare {
  width: 25%;
}

.locatie_istoric_select {
  display: flex;
  justify-content: flex-start;
  width: 75%;
  margin-left: 10px;
}
.locatie_istoric_select_locatie{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.locatie_istoric_select_item {
  width: 100%;
}

.locatie_lucrare_wrapper_big {
  margin-top: 10px;
  width: 100%;
}
.istoric_item {
  margin-bottom: 5px;
  width: 100%;
}

@media (prefers-color-scheme: dark) {
  .istoric_item {
    color: white;
  }
}
.locatie_istoric_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #ddd;
  border-radius: 0.3rem;
  padding: 5px;
  width: 100%;
}
.locatie_istoric_and_gallery_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-radius: 0.3rem;
  width: 100%;
}

.locatie_istoric_istoric {
  background-color: #1e7fcb;
  color: white;
  padding: 5px 10px;
  border-radius: 0.3rem;
}

.locatie_istoric_istoric:hover {
  cursor: pointer;
  background-color: #545b62;
}

.locations_add:hover {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: baseline;
}
.locations_search_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-plus {
  font-size: 22px;
}

.locationItem_wrapper {
  margin-top: 5px;
}

.locatieInputWrapper p {
  font-size: 16px;
  color: #1e7fcb;
  font-weight: 500;
  width: auto;
  min-width: 90px;
  word-break: break-word;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  margin-right: 5px;
}

.locatieInputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.location_inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scadenta_locatie_psi {
  width: 25%;
  margin-left: 10px;
}
.input_locatie_instalatie_psi {
  width: 65%;
  border: 1px solid black;
  padding: 3px;
  border-radius: 0.3rem;
}
.input_locatie {
  width: 90%;
  border: 1px solid black;
  padding: 3px;
  border-radius: 0.3rem;
}

.istoric_locatie_button {
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 0.3rem;
  width: 25%;
}

.istoric_locatie_button:hover {
  cursor: pointer;
}

.locatie_istoric_and_gallery_button{
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 0.3rem;
  height: 40px;
}

.locatie_istoric_and_gallery_button:hover {
  cursor: pointer;
}

.input_locatie_error {
  width: 80%;
  padding: 3px;
  border-radius: 0.3rem;
  border: 1px solid black;
  animation-name: locationError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes locationError {
  0% {
    border: 2px solid #ffffff;
  }
  50% {
    border: 2px solid #c60800;
  }
  100% {
    border: 2px solid #ffffff;
  }
}

.locatie_item_locatie {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  cursor: pointer;
  width: "auto";
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.locatie_item_locatie:hover {
  cursor: pointer;
}

.locatie_item_client {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff7e6; */
  border-left: 3px solid white;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  cursor: pointer;
  width: "auto";
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.locatie_item_descriere {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff7e6; */
  border-left: 3px solid white;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  cursor: pointer;
  width: "auto";
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.locatie_item_descriere_obs {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff7e6; */
  border-left: 3px solid white;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  cursor: pointer;
  width: 99%;
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.locatie_item_descriere_with_observarii {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fff7e6; */
  border-left: 3px solid white;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  cursor: pointer;
  width: "auto";
  word-break: break-word;
  padding-left: 0px;
  padding-right: 0px;
}

.lucrare_item_data {
  background-color: #fff7e6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  height: 40px;
}

.locatie_toggle_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
}

.locatie_toggle_wrapper p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-right: 5px;
  margin-left: 5px;
}

.inactivActiv {
  color: #c60800;
}

.activeActiveP {
  color: green;
}

.inactiveP {
  color: #ddd;
}

.backgroudLocatieInactiv {
  background-color: #dddddd;
}

.backgroudLocatieActive {
  background-color: #fff7e6;
}