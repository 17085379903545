.observatii_wrapper {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.tabs_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #ddd;
  height: 46px;
}

.tabs_wrapper:hover {
  cursor: pointer;
}

.tab_active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 10px;
  height: 100%;
}

.tab_not_active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.tab_text_active {
  margin-top: 7px;
  color: #1e7fcb;
  font-weight: 700;
  font-size: 18px;
}

.tab_text_not_active {
  margin-top: 7px;
  font-size: 16px;
}

.tabs_wrapper_locatie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: #adcdff;
  height: 46px;
}

.tabs_wrapper_locatie:hover {
  cursor: pointer;
}

.tab_active_locatie {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 10px;
  height: 100%;
}

.tab_not_active_locatie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.tab_text_active_locatie {
  margin-top: 7px;
  color: #d11a2a;
  font-weight: 700;
  font-size: 18px;
}

.tab_text_not_active_locatie {
  margin-top: 7px;
  font-size: 16px;
  color: white;
}
