@media (prefers-color-scheme: dark) {
  .login_wrapper{
    background-color: var(--center-side-dark-color);
  }
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.login_button_wrapper {
  margin-top: 20px;
}

.login_error {
  width: 450px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 0, 0, 0.7);
}

.login_error span {
  font-size: 20px;
  font-weight: 500;
}

@media (min-width: 320px) {
  .login_wrapper .login_logo {
    width: 250px;
    height: 250px;
  }
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media (min-width: 480px) {
  .login_wrapper .login_logo {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 600px) {
  .login_wrapper .login_logo {
    width: 300px;
    height: 300px;
  }
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
  .login_wrapper .login_logo {
    width: 350px;
    height: 350px;
  }
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  .login_wrapper .login_logo {
    width: 350px;
    height: 350px;
  }
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  .login_wrapper .login_logo {
    width: 350px;
    height: 350px;
  }
  /* hi-res laptops and desktops */
}
