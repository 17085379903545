.rightComponentWrapper {
  background-color: #ddd;
  margin-top: 10px;
  padding: 5px;
  border-radius: 0.3rem;
  width: auto;
  word-break: break-word;
  height: auto;
  min-height: 40px;
}

.rightComponentLabel {
  font-size: 16px;
  color: #1E7FCB;
  font-weight: 500;
  width: auto;
  word-break: break-word;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
}

.rightComponentLabelRed {
  font-size: 16px;
  color: #c60800;
  font-weight: 500;
  width: auto;
  word-break: break-word;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
}

.rightComponentValue {
  font-weight: 500;
  font-size: 16px;
  width: auto;
  word-break: break-word;
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
}

.rightComponentWrapperButtons{
  background-color: white;
  text-align: left;
  display: flex;
  flex-direction: row;
}

@media (prefers-color-scheme: dark) {
  .rightComponentWrapperButtons{
    background-color: var(--right-side-dark-color);
  }
}