.pontaj_item_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 0.3rem;
  margin-top: 10px;
  width: 97%;
  height: 55px;
}

.pontaj_istoric_item_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 0.3rem;
  margin-top: 10px;
  width: 97%;
}


.tab_wrapper {
  height: calc(100vh - 50px);
  overflow: scroll;
}

.tab_pontaje_tehnician {
  height: calc(100vh - 150px);
  overflow: scroll;
}
.tab_pontaje_validare {
  height: calc(100vh - 100px);
  overflow: scroll;
}
.tab_wrapper_detalii {
  height: auto;
  overflow: auto;
}

.pontajBackgroundSambata {
  background-color: #49678c;
  border: 1px solid #49678c;
}
.pontajBackgroundDuminica {
  background-color: #a16665;
  border: 1px solid #a16665;
}

.pontajBackground {
  background-color: #96afc5;
}

.text_color_white {
  color: white;
}
.text_color_black {
  color: black;
}
.pontaj_item_up {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.pontaj_item_down {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 95%;
  border-radius: 0.3rem;
  margin-bottom: 5px;
}

.pontaj_item_up_user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
}

.istoric_salariu_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 95%;
  border-radius: 0.3rem;
  margin-bottom: 5px;
}

.istoric_salariu_item_month {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
  background-color: #49678c;
}

.pontaj_item_up_validator {
  width: 10%;
  padding-right: 10px;
}

.pontaj_rezumat_first_line_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #bbb;
  border-radius: 0.3rem;
  margin-top: 10px;
  width: 97%;
}

.pontaj_rezumat_second_line_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ddd;
  border-radius: 0.3rem;
  margin-top: 10px;
  width: 97%;
}

.pontaj_rezumat_3rd_line_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ddd;
  border-radius: 0.3rem;
  margin-top: 10px;
  width: 97%;
}

.backgroundColorGreen {
  background-color: #abda6d;
}
.backgroundColorWhite {
  background-color: white;
}

.pontaj_tehnician_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.ponaj_tehnian_nume {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.ponaj_tehnian_data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.ponaj_tehnian_ore_lucrate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 80%;
}

.ponaj_tehnian_ore_interventii {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 80%;
}

.ponaj_tehnian_ore_interventii_1 {
  margin-right: 84px;
  width: 20px;
  height: 20px;
}

.ponaj_tehnian_ore_interventii_2 {
  width: 20px;
  height: 20px;
}

.ponaj_tehnian_ore_telefon_1 {
  margin-right: 104px;
  width: 20px;
  height: 20px;
}

.ponaj_tehnian_ore_telefon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 80%;
}

.ponaj_tehnian_ore_dispecer_options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 80%;
}

.ponaj_tehnian_ore_validare {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 15px;
  width: 90%;
}

.ponaj_tehnian_ore_validare_btton_not_valid {
  background-color: #ddd;
  border-color: #ddd;
  color: black;
}

.ponaj_tehnian_ore_validare_btton_valid {
  background-color: #abda6d;
  border-color: #abda6d;
  color: black;
}

.ponaj_tehnian_ore_validare_btton_not_valid:hover {
  background-color: #ddd;
  border-color: #aaa;
  color: black;
}

.ponaj_tehnian_ore_validare_btton_valid:hover {
  background-color: #abda6d;
  border-color: #abda6d;
  color: black;
}

.pontaj_label_text {
  font-size: 20px;
  color: #1e7fcb;
  font-weight: 500;
}
