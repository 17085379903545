:root {
  --left-side-dark-color: #505050;
  --center-side-dark-color: #404040;
  --right-side-dark-color: #505050;
}

.leftDashboard_wrapper {
  background-color: #ddd;
  height: 100vh;
  position: relative;
}

.leftDashboard_wrapper span {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent;
  display: hidden;
  color: #ddd;
}
.leftDashboard_wrapper span i {
  color: #ddd;
  display: hidden;
}
.headerWrapper {
  background-color: #dddddd;
  width: 100%;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.headerWrapper_location {
  background-color: #adcdff;
  width: 100%;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.middleScreen {
  height: calc(100vh - 65px);
  overflow: auto;
  overflow-y: scroll;
  /*  */
  background-color: white;
}

.middleScreenFolder {
  height: calc(100vh - 65px);
  overflow: auto;
  overflow-y: scroll;
  /*  */
  background-color: black;
}

.middleScreenLocations {
  height: calc(100vh - 100px);
  overflow: auto;
  overflow-y: scroll;
  background-color: black;
}

.mainPage {
  background-color: white;
}

.col_leftDashboard_wrapper {
  padding-left: 0;
  margin-left: 0;
}


@media (prefers-color-scheme: dark) {
  .middleScreen {
    background-color: var(--center-side-dark-color);
  }

  .middleScreenFolder {
    background-color: var(--center-side-dark-color);
  }

  .middleScreenLocations {
    background-color: var(--center-side-dark-color);
  }

  .mainPage {
    background-color: var(--center-side-dark-color);
  }

  .leftDashboard_wrapper {
    background-color: var(--left-side-dark-color);
  }
  
  .leftDashboard_wrapper span {
    background-color: var(--left-side-dark-color);
  }

  .col_leftDashboard_wrapper {
    /* could be center */
    background-color: var(--center-side-dark-color);
  }

  .right_dashboard {
    background-color: var(--left-side-dark-color);
  }

  .leftDashboard_wrapper span i {
    color: var(--left-side-dark-color);
  }

  .black_screen {
    background-color: var(--center-side-dark-color);
  }
}

.row_folder {
  width: 100%;
}

.leftDashboard_logo_wrapper {
  width: 100%;
  text-align: center;
}

.leftDashboard_logo_sageti {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-top: 7px;
}

.new_media_black {
  width: 40px;
  height: 35px;
  margin-top: 7px;
  margin-left: 10px;
}

.new_media_black:hover {
  cursor: pointer;
}

.new_media {
  width: 40px;
  height: 35px;
  margin-top: 7px;
  margin-left: 10px;
}

.new_media:hover {
  cursor: pointer;
}

.leftDashboard_logo {
  width: 120px;
  margin-bottom: 10px;
  margin-top: 14px;
}

.interventii_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #98a7d7;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.ascensoare_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #9ec1e0;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.ascensoare_header:hover {
  width: 40%;
  text-align: center;
  background-color: #5190c8;
  cursor: pointer;
}
.pontaje_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pontaje_header:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.fisaZilnica_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.fisaZilnica_header:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.pontaj_header_title {
  width: 25%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
}

.pontaj_header_useri_wrapper {
  display: flex;
  flex-direction: row;
  width: 35%;
}
.pontaj_header_user_item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe7b3;
  margin-left: 10px;
  width: 40px;
  min-width: 40px;
  height: 30px;
  min-height: 30px;
  border-radius: 0.3rem;
}
.pontaj_header_user_item:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.pontaj_header_user_item_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe7b3;
  margin-left: 10px;
  width: 40px;
  min-width: 40px;
  height: 30px;
  min-height: 30px;
  border-radius: 0.3rem;
  border: 2px solid black;
}
.pontaj_header_user_item_selected:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.pontaj_header_date_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  margin-right: 10px;
}

.pontaj_header_date_sageti_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.pontaj_header_date_sageti_wrapper:hover {
  cursor: pointer;
}
.date_picker_input {
  width: 95%;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.interfoane_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #a6d8b9;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.interfoane_header:hover {
  width: 40%;
  text-align: center;
  background-color: #4eb174;
  cursor: pointer;
}

.revizii_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #8bc2d0;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.revizii_header:hover {
  width: 40%;
  text-align: center;
  background-color: #69bde2;
  cursor: pointer;
}

.iscir_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #bacfff;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.iscir_header:hover {
  width: 40%;
  text-align: center;
  background-color: #69bde2;
  cursor: pointer;
}
.psi_header_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.psi_header {
  width: 20%;
  height: 100%;
  text-align: center;
  background-color: #f1b2ac;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.psi_header:hover {
  width: 20%;
  text-align: center;
  background-color: #e9857c;
  cursor: pointer;
}

.psi_header_months {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.psi_header_month {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe7b3;
  margin-left: 10px;
  width: 45px;
  min-width: 45px;
  height: 30px;
  min-height: 30px;
  border-radius: 0.3rem;
}
.psi_header_month:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.psi_header_month_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe7b3;
  margin-left: 10px;
  width: 45px;
  min-width: 45px;
  height: 30px;
  min-height: 30px;
  border-radius: 0.3rem;
  border: 2px solid black;
}
.psi_header_month_selected:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.iscir_header_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bacfff;
  margin-left: 10px;
  width: 80px;
  min-width: 80px;
  height: 35px;
  min-height: 35px;
  border-radius: 0.3rem;
}
.iscir_header_button:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.iscir_header_button_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bacfff;
  margin-left: 10px;
  width: 80px;
  min-width: 80px;
  height: 35px;
  min-height: 35px;
  border-radius: 0.3rem;
  border: 2px solid black;
}
.iscir_header_button_selected:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.administrativ_header_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bacfff;
  margin-left: 10px;
  width: 100px;
  min-width: 80px;
  height: 35px;
  min-height: 35px;
  border-radius: 0.3rem;
}
.administrativ_header_button:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.administrativ_header_button_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bacfff;
  margin-left: 10px;
  width: 100px;
  min-width: 80px;
  height: 35px;
  min-height: 35px;
  border-radius: 0.3rem;
  border: 2px solid black;
}
.administrativ_header_button_selected:hover {
  background-color: #c5b64c;
  cursor: pointer;
}

.altele_header:hover {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #c5b64c;
  cursor: pointer;
}

.altele_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #d0c362;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.sediuMasini_header:hover {
  width: 40%;
  text-align: center;
  background-color: #c5b64c;
  cursor: pointer;
}

.sediuMasini_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.diverse_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #fdcaa6;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.diverse_header:hover {
  width: 40%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.media_header {
  width: 80%;
  height: 100%;
  text-align: center;
  background-color: #fdcaa6;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.media_header:hover {
  width: 80%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.administrativ_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.administrativ_header:hover {
  width: 40%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.comenzi_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.comenzi_header:hover {
  width: 40%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.notificari_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.notificari_header:hover {
  width: 40%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.oferte_header {
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #ffe7b3;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.oferte_header:hover {
  width: 40%;
  text-align: center;
  background-color: #ffd780;
  cursor: pointer;
}

.left_dashboard_number_wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}
.nr_lucrari_not_visible {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  border-radius: 20%;
}

.admin_progr_blink_not_read {
  margin-left: 10px;
  animation-name: blink_programate_not_read;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes blink_programate_not_read {
  0% {
    color: black;
  }

  50% {
    color: red;
  }

  100% {
    color: black;
  }
}

.admin_progr_blink_read {
  margin-left: 10px;
  animation-name: blink_programate_read;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes blink_programate_read {
  0% {
    color: black;
  }

  50% {
    color: #ffe7b3;
  }

  100% {
    color: black;
  }
}
.nr_lucrari_faza_3_4_blink {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  /* background-color: #ff841a; */
  font-size: 15px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
  border-radius: 20%;
  animation-name: blink_lucrari_3_4;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes blink_lucrari_3_4 {
  0% {
    background-color: #fee5cd;
    color: black;
  }
  50% {
    background-color: #febb66;
    color: white;
  }
  100% {
    background-color: #fee5cd;
    color: black;
  }
}

.textDomeniuRead {
  margin-left: 10px;
  color: black;
}

.textDomeniuNotRead {
  margin-left: 10px;
  color: #c60800;
}

.nr_lucrari_faza_1_2 {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  background-color: #fef8d7;
  font-size: 15px;
  font-weight: 500;
  color: black;
  border: 1px solid black;
  border-radius: 20%;
}

.nr_lucrari_faza_3_4 {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  background-color: #ff841a;
  font-size: 15px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
  border-radius: 20%;
}

.nr_lucrari_faza_5_6_blink {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  /* background-color: #ff841a; */
  font-size: 15px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
  border-radius: 20%;
  animation-name: blink_lucrari_5_6;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes blink_lucrari_5_6 {
  0% {
    background-color: #ffffff;
    color: black;
  }
  50% {
    background-color: #06a559;
    color: white;
  }
  100% {
    background-color: #ffffff;
    color: black;
  }
}

.nr_lucrari_faza_5_6 {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  background-color: #06a559;
  font-size: 15px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
  border-radius: 20%;
}
.nr_lucrari_faza_7 {
  width: 24px;
  height: 20px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  background-color: #0273b7;
  font-size: 15px;
  font-weight: 500;
  color: white;
  border: 1px solid black;
  border-radius: 20%;
}
.leftDashboard_item_selected {
  border: 2px solid black;
}

.leftDashboard_item_fisa_zilnica {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  height: 40px;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}

.leftDashboard_item_administrativ {
  width: 50%;
  display: flex;
  flex-direction: "row";
  justify-content: space-between;
  align-items: center;
  background-color: #ffe7b3;
  height: 100%;
  border-radius: 0.3rem;
}
.leftDashboard_item_administrativ:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_oferte {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  flex-direction: "row";
  background-color: #ffe7b3;
  height: 100%;
  border-radius: 0.3rem;
}
.leftDashboard_item_oferte:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_interventii {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #98a7d7;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_interventii:hover {
  cursor: pointer;
  background-color: #98a7b5;
}
.leftDashboard_item_ascensoare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #9ec1e0;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_ascensoare:hover {
  cursor: pointer;
  background-color: #77a9d4;
}
.leftDashboard_item_interfoane {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #a6d8b9;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_interfoane:hover {
  cursor: pointer;
  background-color: #83c99e;
}
.leftDashboard_item_psi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #f1b2ac;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_psi:hover {
  cursor: pointer;
  background-color: #ed9a92;
}

.leftDashboard_item_altele {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #d0c362;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_altele:hover {
  cursor: pointer;
  background-color: #c5b64c;
}

.leftDashboard_item_sediuMasini {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #ffe7b3;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_sediuMasini:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_diverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #fdcaa6;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_diverse:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_revizii {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #8bc2d0;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_revizii:hover {
  cursor: pointer;
  background-color: #69bde2;
}

.leftDashboard_item_iscir {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #bacfff;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_iscir:hover {
  cursor: pointer;
  background-color: #69bde2;
}

.leftDashboard_item_comenzi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #ffe7b3;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_comenzi:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_setari {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
  background-color: #ffe7b3;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-top: 5px;
  margin-left: 5px;
}
.leftDashboard_item_setari:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_pontaj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  flex-direction: "row";
  background-color: #ffe7b3;
  height: 100%;
  border-radius: 0.3rem;
}

.leftDashboard_item_fisaZ {
  display: flex;
  width: 48%;
  background-color: #ffe7b3;
  border-radius: 0.3rem;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.leftDashboard_item_pontaj:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.leftDashboard_item_fisaZ:hover {
  cursor: pointer;
  background-color: #ffd780;
}

.sageata_up {
  width: 15px;
  height: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid black;
  margin-bottom: 5px;
}
.sageata_up_disabled {
  width: 15px;
  height: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #aaa;
  margin-bottom: 5px;
}

.sageata_down {
  width: 15px;
  height: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid black;
}

.no_internet_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no_internet_text {
  font-size: 24px;
}
.lucrari_cu_fisiere_header{
  width: 40%;
  height: 100%;
  text-align: center;
  background-color: #fdcaa6;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
