.selectedFaza {
  border: 3px solid black;
}
.Select-menu-outer .Select-create-option-placeholder {
  display: none;
}
.scadenta_iscir_finalizare_lucrare {
  width: 35%;
}

.scadenta_iscir_finalizare_lucrare_error {
  width: 35%;
  animation-name: locationError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.locatieScadentaError {
  width: 25%;
  animation-name: locationError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.locatieError {
  width: 60%;
  margin: auto;
  display: block;
  animation-name: locationError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes locationError {
  0% {
    border: 2px solid #ffffff;
  }
  50% {
    border: 2px solid #c60800;
  }
  100% {
    border: 2px solid #ffffff;
  }
}

.descriereError {
  animation-name: descrierError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  border: 2px solid #ffffff;
}

.scadentaError {
  animation-name: descrierError;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  border: 2px solid #ffffff;
  width: 95%;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

@keyframes descrierError {
  0% {
    border: 2px solid #ffffff;
  }
  50% {
    border: 2px solid #c60800;
  }
  100% {
    border: 2px solid #ffffff;
  }
}

.deleteLucrareButton {
  width: 40px;
  height: 40px;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteLucrareButton:hover {
  cursor: pointer;
}

.lucrareLocatie {
  width: 60%;
  margin: auto;
  display: block;
}
.input-select-dropdown-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 70%;
  z-index: 3;
  position: absolute;
}
.xxx .css-b8ldur-Input {
  color: #c60800 !important;
}

.yyy .css-b8ldur-Input {
  color: black !important;
}
/* .css-b8ldur-Input{
  color:blue !important;
} */

.finalizare_lucrare_masini_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.finalizare_lucrare_alimentare_text {
  display: inline-block;
  width: 17%;
  text-align: left;
}

.finalizare_lucrare_km {
  width: 70%;
  margin-bottom: 10px;
}
.finalizare_lucrare_litrii {
  width: 70%;
}

.lucrare_dispecer_alimentare_combustibil {
  background-color: #1c73c5;
  color: white;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border-radius: 0.3rem;
}

.lucrare_dispecer_faza1 {
  background-color: #c4bdb1;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border-radius: 0.3rem;
}
.lucrare_dispecer_faza1:hover {
  background-color: #d4dfd7;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border: 2px solid #000000;
  cursor: pointer;
}

.lucrare_dispecer_faza2 {
  background-color: #fef3b8;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border-radius: 0.3rem;
}
.lucrare_dispecer_faza2:hover {
  background-color: #fef3c8;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border: 2px solid #000000;
  cursor: pointer;
}

.lucrare_dispecer_faza3 {
  background-color: #fddd3f;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border-radius: 0.3rem;
}
.lucrare_dispecer_faza3:hover {
  background-color: #fddd4f;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border: 2px solid #000000;
  cursor: pointer;
}

.lucrare_dispecer_faza4 {
  background-color: #fda04c;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border-radius: 0.3rem;
}
.lucrare_dispecer_faza4:hover {
  background-color: #fda05c;
  height: 25px;
  width: 50%;
  margin-left: 3%;
  border: 2px solid #000000;
  cursor: pointer;
}

.lucrare_dispecer_faza4_rosu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c60800;
  height: 25px;
  width: 50%;
  color: white;
  margin-left: 3%;
  border-radius: 0.3rem;
}
.lucrare_dispecer_faza4_rosu:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c6080d;
  height: 25px;
  width: 50%;
  color: white;
  margin-left: 3%;
  border: 2px solid #000000;
  cursor: pointer;
}

.lucrare_useri_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modificare_stergere_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modificare_stergere_row {
  display: flex;
  /* justify-content: center; */
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.factura_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error_descriere_instalatie {
  animation-name: qwe;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  border: 1px solid black;
}

.error_factura {
  animation-name: qwe;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  border: 1px solid c60800;
}
@keyframes qwe {
  0% {
    border: 2px solid #ffffff;
  }
  50% {
    border: 2px solid #c60800;
  }
  100% {
    border: 2px solid #ffffff;
  }
}
.rightDashboardDetaliiWrapper {
  height: calc(100vh - 65px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 6px;
}
