.inputField_wrapper {
  display: flex;
  flex-direction: column;
}

.inputField_wrapper .inputField_label {
  font-style: italic;
}

.inputField_wrapper .inputField_input {
  border: none;
  border-bottom: 1px solid black;
  height: 30px;
}
@media (min-width: 320px) {
  .inputField_wrapper .inputField_input {
    width: 250px;
  }
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
}
@media (min-width: 480px) {
  .inputField_wrapper .inputField_input {
    width: 350px;
  }
}
@media (min-width: 600px) {
  .inputField_wrapper .inputField_input {
    width: 400px;
  }
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
}
@media (min-width: 801px) {
  .inputField_wrapper .inputField_input {
    width: 450px;
  }
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  .inputField_wrapper .inputField_input {
    width: 450px;
  }
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  .inputField_wrapper .inputField_input {
    width: 450px;
  }
  /* hi-res laptops and desktops */
}
