.lucrare_item_locatie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: auto;
  min-height: 40px;
  font-weight: 400;
  width: auto;
  line-height: 20px;
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  border-right: 3px solid white;
}
.lucrare_folder_data{
  background-color:#ffe7b3;
  min-height: 40px;
  height: auto; 
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  width: auto;
  line-height: 20px;
  word-break: break-word; 
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-right: 3px solid white;
}

.lucrare_folder_nume{
  background-color:#87cefa;
  min-height: 40px
}

.carousel{
  background-color: white;
  display: 'flex';
  /* height: '100vh'  */
}

.box{
  max-width: 500px;
  margin: auto;
 height: auto;
}
.body_img{ 
   margin-bottom: 10px;
}

.lucrare_item_locatie_nr_interventii {
  color: #c60800;
}

.lucrare_item_muta_scadenta_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.lucrare_item_muta_scadenta {
  width: 50%;
}

.lucrari_not_found {
  text-align: center;
  font-size: 21px;
}

@media (prefers-color-scheme: dark) {
  .lucrari_not_found{
    color: white;
  }

  .lucrare_item_locatie {
    border-right: 3px solid  var(--center-side-dark-color);
  }

  .lucrare_folder_data {
    border-right: 3px solid  var(--center-side-dark-color);
  }
}

.setari_numere_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 125px;
}

.lucrareItemLocatieTextColorRed {
  color: #c60800;
}

.lucrareItemLocatieTextColorNormal {
  color: black;
}

.lucrareItemLocatieRevizieFaza2 {
  color: #fef8d7;
  animation-name: blink_revizie_2;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.lucrareItemLocatieRevizieFaza3 {
  color: #fee35e;
  animation-name: blink_revizie_3;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.lucrareItemLocatieRevizieFaza4 {
  color: #febb66;
  animation-name: blink_revizie_4;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes blink_revizie_2 {
  0% {
    color: black;
  }

  50% {
    color: #fef8d7;
  }

  100% {
    color: black;
  }
}

@keyframes blink_revizie_3 {
  0% {
    color: black;
  }
  50% {
    color: #fee35e;
  }
  100% {
    color: black;
  }
}

@keyframes blink_revizie_4 {
  0% {
    color: black;
  }
  50% {
    color: #febb66;
  }
  100% {
    color: black;
  }
}

.lucrare_item_locatie:hover {
  cursor: pointer;
}

.lucrare_finalizare_descriere_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lucrare_finalizare_descriere {
  background-color: #ddd;
  width: 100%;
  padding: 5px;
  border-radius: 0.3rem;
}

.serie_nr_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.factura_nr_input {
  height: 36px;
}

.backgroudLocatieInterventii {
  background-color: #98a7d7;
}

.backgroudLocatieAscensoare {
  background-color: #9ec1e0;
}

.backgroudLocatieIscir {
  background-color: #bacfff;
}

.backgroudLocatieRevizii {
  background-color: #8bc2d0;
}

.backgroudLocatieInterfoane {
  background-color: #a6d8b9;
}

.backgroudLocatiePsi {
  background-color: #f1b2ac;
}

.backgroudLocatieAltele {
  background-color: #d0c362;
}

.backgroudLocatieSediuMasini {
  background-color: #ffe7b3;
}

.backgroudLocatieDiverse {
  background-color: #fdcaa6;
}

.backgroudLocatieOferte {
  background-color: #ffe7b3;
}

.backgroudLocatieAdministrativ {
  background-color: #ffe7b3;
}

.backgroudLocatieNotificari {
  background-color: #ffe7b3;
}

.backgroudLocatieComenzi {
  background-color: #ffe7b3;
}

.backgroundLocatieDefault {
  background-color: #fffff5;
}

.selectedLucrare {
  border: 2px solid black;
  margin-bottom: 4px;
  border-radius: 0.3rem;
}

.newLucrare {
  border: 2px solid #bc0478;
  margin-bottom: 4px;
  border-radius: 0.3rem;
}

.lucrare_item_detalii {
  background-color: #fffff5;
}

.lucrare_item_descriere_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.baraRosieObservatii {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #c60800;
  width: 1%;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.lucrare_item_descriere {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  width: "auto";
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-right: 3px solid white;
}

.lucrare_item_descriere_with_obs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  width: "auto";
  word-break: break-word;
  padding-left: 5px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-right: 3px solid white;
}

.lucrare_item_descriere_obs_wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 0px;
  padding-bottom: 0px;
}

.lucrare_item_descriere_obs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 40px;
  line-height: 20px;
  width: 99%;
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.lucrare_item_data {
  background-color: #fff7e6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 20px;
  cursor: pointer;
  height: auto;
  min-height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.lucrare_item_date_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lucrare_item_date {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  font-size: 15px;
}

.lucare_faze_dispecer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lucrare_item_descriere_discutie {
  background-color: #87cefa;
}

.lucrare_item_descriere_faza1 {
  background-color: #ccc5b9;
}

.lucrare_item_descriere_faza2 {
  background-color: #fef8d7;
}

.lucrare_item_descriere_faza3 {
  background-color: #fee35e;
}

.lucrare_item_descriere_faza4 {
  background-color: #febb66;
}

.lucrare_item_descriere_faza5 {
  animation-name: faza5;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes faza5 {
  0% {
    background-color: #ecf8db;
  }

  50% {
    background-color: #c1e689;
  }

  100% {
    background-color: #ecf8db;
  }
}

.lucrare_item_descriere_atribuiti {
  animation-name: atribuiti;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes atribuiti {
  0% {
    background-color: #fee5cd;
  }

  50% {
    background-color: #febb66;
  }

  100% {
    background-color: #fee5cd;
  }
}

.lucrare_item_descriere_faza6 {
  background-color: #c1e689;
}

.lucrare_item_descriere_faza7 {
  background-color: #5ea5e8;
}

.lucrare_item_descriere_faza8 {
  background-color: #98a7d7;
}

.lucrare_muta_data_wrapper {
  background-color: #98a7d7;
  border-radius: 0.3rem;
  text-align: center;
  width: 3.2rem;
  font-weight: 500;
  padding: 3px;
}

.button_finalizare_lucrare {
  background-color: #425ba9;
}

.button_finalizare_lucrare:hover {
  background-color: #425bb9;
}

.button_generare_psi {
  background-color: #fee35e;
  color: black;
}

.button_generare_psi:hover {
  background-color: #fddd4f;
  color: black;
}

.button_istoric {
  background-color: #2280cc;
}

.descriereLucrareInterventie {
  color: #c60800;
}

.descriereLucrareTextFaza7 {
  color: white;
}

.descriereLucrareTextWhite {
  color: white;
}

.background_color_programata_depasire_scadenta{
  background-color: #d62828;
}

.background_color_programata_activa{
  background-color: #e0ffcd;
}

.finalizare_lucrare_serie_nr_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "center";
}

.de_discutat_useri_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.de_discutat_list_useri_wrapper {
  margin-right: 20px;
}

.de_discutat_checkbox {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.lucrare_item_participanti {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.lucrare_item_user_participant_curent {
  color: #afdf68;
  font-weight: bold;
}

.descriere_discutie_text_color {
  color: #c60800;
}

.lucrare_item_user_participant_not_curent {
  color: black;
}

.nr_telefon {
  color: #4361ee;
  text-decoration: underline;
}

.nr_telefon:hover {
  cursor: pointer;
}